import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    fairOaksImage: file(relativePath: { eq: "fair-oaks.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInCarImage: file(relativePath: { eq: "leo-in-car.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function FairOaksPage({ data }) {
  return (
    <MainLayout
      title="24 Hour Emergency Locksmith Fair Oaks, CA | 916-995-0135"
      description="Get premium locksmith services from our licensed pros. We are always available, affordable & fast. Offering home, car & business locksmith services."
      offset
    >
      <ContactHeading background={data.fairOaksImage.childImageSharp.fluid}>
        <h1 className="text-center">Fair Oaks Trusted Locksmith</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith in Fair Oaks, CA
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">Your Locksmith in Fair Oaks, CA</h2>
        <Row>
          <Col xs={12} md={{ span: 7, order: 2 }} lg={6} xl={8}>
            <p className="indent">
              Swift Locksmith is one of the most trusted names within the
              locksmith business. We provide exceptional services for Fair Oaks,
              CA and the surrounding areas. We offer complete residential,
              commercial, and automotive locksmith services, 7 days per week, 24
              hours a day. Our work is fully guaranteed and we're insured and
              licensed so you know you are in good hands with Swift Locksmith in
              Fair Oaks.
            </p>
            <p className="indent mb-md-0">
              In addition to providing great locksmith services, our expert team
              is always happy to inform you about different options and security
              solutions for a safer home, vehicle or business. We always put our
              customers' needs first and that is why we personalize each and
              every service to meet the needs of every individual.
            </p>
          </Col>
          <Col
            xs={12}
            md={5}
            lg={{ span: 5, order: 1 }}
            xl={4}
            className="col-sm-pad"
          >
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-van-in-city"
            />
          </Col>
        </Row>
      </Container>
      <Container as="section">
        <h2>What We Offer</h2>
        <h3>Residential Locksmith</h3>
        <p>
          We provide an assortment of residential locksmith services for your
          property ranging from basic to high security locksmith solutions.
          Whether you need your locks changed, re-key your lock, or even simply
          modify your mailbox lock, we will look after you.
        </p>
        <h3>Commercial Locksmith</h3>
        <p>
          Get professional locksmith services for your Fair Oaks business. We've
          been providing commercial locksmith solutions for Fair Oaks businesses
          for over 10 years and our locksmith technicians are experts at
          providing different security solutions for all types of organizations.
          From door hardware to locks we do it all.
        </p>
        <h3>Automotive Locksmith</h3>
        <p className="mb-0">
          Locked out from your own car/truck? Lost your keys? We will come to
          you to unlock your vehicle, duplicate a brand-new key when necessary
          or restore your automobile lock immediately. Give us a call and we can
          send a technician immediately to assist you.
        </p>
      </Container>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>Why Choose Swift Locksmiths in Fair Oaks, CA</h2>
        <Row>
          <Col xs={12} md={6} className="mb-3 mb-md-0">
            <ul className="mb-0">
              <li className="lead">Honest Business Practices</li>
              <li className="lead">Dependable & Quick Response Time</li>
              <li className="lead">24/7 Emergency Mobile Services</li>
              <li className="lead">
                Professional, Friendly, Expert Technicians
              </li>
              <li className="lead">Impeccable Customer Service</li>
              <li className="lead">Affordable Prices</li>
            </ul>
          </Col>
          <Col xs={12} md={6} className="col-sm-pad">
            <Img
              fluid={data.leoInCarImage.childImageSharp.fluid}
              alt="leo-in-car"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default FairOaksPage
